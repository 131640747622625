<template>
  <div>
    <el-form ref="emailformref" :model="emailform" label-width="180px">
      <el-form-item :label="$t('cs.qdyxyz')" required>
        <el-switch
          v-model="emailform.email_isopend"
          active-value="1"
          inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <el-form-item :label="$t('cs.smtpfwq')">
        <el-input v-model="emailform.smtp_service"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.smtpdk')">
        <el-input v-model="emailform.smtp_port"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.fjyxdz')">
        <el-input v-model="emailform.send_email"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.yxyhm')">
        <el-input v-model="emailform.email_account"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.yxmm')">
        <el-input v-model="emailform.email_password"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.fjrmc')">
        <el-input v-model="emailform.send_person"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="reset">{{ $t('chongzhi') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      emailform: {
        email_isopend: '',
        smtp_service: '',
        smtp_port: '',
        send_email: '',
        email_account: '',
        email_password: '',
        send_person: ''
      }
    }
  },
  created() {
    this.getemail()
  },
  methods: {
    async getemail() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/config/emailconfig')
      if (data) {
        if (data.code === 200) {
          this.emailform = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交
    async onSubmit() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.post(
        '/admin/config/emailconfig',
        this.emailform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 重置
    reset() {
      this.getemail()
    }
  }
}
</script>
